.container {
  & .header {
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: var(--font-size--header);
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px dashed var(--color-border-title);

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: calc(50% - 9px);
      left: 0;
      border-radius: 50%;
      animation: flicker 1.5s infinite alternate;
    }
  }

  & .item {
    width: 100%;
    margin-bottom: 25px;
  }

  & .itemPass {
    width: 100%;
    margin-bottom: 10px;
  }

  & .badg {
    margin-bottom: 25px;
  }

  & .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
    padding-top: 10px;
    border-top: 1px dashed var(--color-border-title);
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    background-color: var(--color-main);
  }

  20%,
  24%,
  55% {
    background-color: var(--color-mainBg);
  }
}
