.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    font-weight: bold;
    font-size: var(--font-size--header);
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px dashed var(--color-border-title);

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: calc(50% - 9px);
      left: 0;
      border-radius: 50%;
      animation: flicker 1.5s infinite alternate;
    }

    & .title {
      font-size: 0.9375rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .weekSelect {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .pageName {
    font-size: var(--font-size--header);
    font-weight: bold;
  }

  & .curse {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    & p {
      display: flex;
      align-items: center;
      font-weight: bold;

      & svg {
        margin-right: 7px;
      }

      & span {
        margin-left: 7px;
        color: var(--color-main);
      }
    }
  }
}

.loadSpin {
  & svg {
    animation: spinner 1500ms infinite linear;
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    background-color: var(--color-main);
  }

  20%,
  24%,
  55% {
    background-color: var(--color-mainBg);
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 750px) {
  .container {
    & .header {
      gap: 20px;
      padding-left: 0;
      flex-direction: column;
      align-items: flex-start;

      &::after {
        top: 9px;
      }

      & .title {
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    align-items: flex-start;
    flex-direction: column;

    & .weekSelect {
      margin-bottom: 20px;
    }
  }
}
