@import '../../node_modules/antd/dist/antd.css';
@import 'light.theme';
@import 'dark.theme';
@import 'antd';

* {
  box-sizing: border-box;
}

//tiny error message disabled
.tox-notifications-container {
  display: none;
}
