.container {
  & .label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: var(--color-font);
    font-weight: bold;
    font-size: var(--font-size);

    & svg {
      position: relative;
      top: -1px;
      margin-right: 7px;
    }
  }
}
