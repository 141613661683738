.container {
  position: relative;
  color: var(--color-font);

  .body {
    display: flex;
    padding: 20px;
    font-size: var(--font-size);

    & .main {
      width: calc(100% - 325px);
      margin: 10px;
      padding: 20px;
      border-radius: 10px;
      background-color: var(--color-elemBg);
    }
  }

  & .body.admin {
    padding-top: 106px;
  }

  & .body.manager {
    padding-top: 106px;

    & .main {
      width: 100%;
    }
  }

  & .loadSpin {
    position: fixed;
    inset: 0;
    z-index: 1000;
    height: 100%;
    background-color: #00000073;

    & svg {
      animation: spinner 1500ms infinite linear;
    }
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .body {
      padding: 20px 20px;

      & .main {
        width: 100%;
        margin: 0;
        padding: 0 10px 10px;
      }
    }

    & .body.admin {
      padding-top: 130px;
    }
  }
}
