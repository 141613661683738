.buttonUi,
.buttonUi.ant-btn:hover,
.buttonUi.ant-btn:focus,
.buttonUi.ant-btn:active {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: var(--font-size);
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background: var(--color-elemBg);
  box-shadow: none;

  svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  color: var(--color-grey);
  opacity: 0.5;
  background: var(--color-elemBg);
}

.buttonUi:hover {
  opacity: 0.5;
}

.buttonUi.buttonUi_main,
.buttonUi[disabled].buttonUi_main {
  color: var(--color-main);
}

.buttonUi.buttonUi_red,
.buttonUi[disabled].buttonUi_red {
  color: var(--color-red);
}

.buttonUi.buttonUi_green,
.buttonUi[disabled].buttonUi_green {
  color: var(--color-green);
}

.buttonUi.buttonUi_yellow,
.buttonUi[disabled].buttonUi_yellow {
  color: var(--color-yellow);
}

.buttonUi.buttonUi_grey,
.buttonUi[disabled].buttonUi_grey {
  color: var(--color-grey);
}

.buttonUi.outlined,
.buttonUi[disabled].outlined {
  background: transparent;
}
