.light {
  --color-mainBg: #ffffff;
  --color-elemBg: #f4f6fa;
  --color-main: #009ef7;
  --color-red: #d94c48;
  --color-blue: #009ef7;
  --color-green: #17bc71;
  --color-grey: #aaaab2;
  --color-font: #3f4254;
  --color-yellow: #ffb74d;
  --color-violet: #5e0cc4;
  --color-border: #d0d0d0;
  --border-green: #d0d0d0;
  --color-border-title: #d0d0d0;
  --badge-bg-main: rgba(0, 158, 247, 0.2);
  --badge-bg-red: rgba(239, 83, 80, 0.15);
  --badge-bg-blue: rgba(0, 158, 247, 0.2);
  --badge-bg-green: rgba(37, 202, 127, 0.2);
  --font-size: 0.8125rem;
  --font-size-small: 0.6rem;
  --font-size-big: 1.3rem;
  --font-size--header: 0.9375rem;
  --color-logo-main: #3f4254;
  --color-logo-second: #009ef7;
  --color-header-main: #ffffff;
  --color-header-second: #f4f6fa;
  --color-btn-menu: rgba(0, 158, 247, 0.15);
  --color-btn-menu-hover: rgba(0, 158, 247, 0.25);
  --color-btn-title-hover: #037abc;
  --color-arrows-date: #3f4254;
  --notification-error: #f8b1b0;
  --notification-success: #bdefd8;
  --login-flecker-bg: #d0d0d0;
  --import-img-opacity: 0.5;
  --input-focus: rgba(0, 152, 255, 0.15);
  --shadow-box: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
  --shadow-drop: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  --shadow-header: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  --not-found: url(../../public/img/icon/clipboard-times-light.svg);
}
