.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--color-elemBg);

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    margin-top: -20%;
    margin-bottom: -12%;
    margin-left: -13%;
    width: 57%;
    transform: translate(0, 0) rotate(-4deg) skew(0) skewY(0) scaleX(1) scaleY(1);
    border-radius: 100%;
    background-color: var(--color-mainBg);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    margin-top: -28%;
    margin-bottom: -15%;
    margin-left: -13%;
    width: 57%;
    transform: translate(0, 0) rotate(-4deg) skew(0) skewY(0) scaleX(1) scaleY(1);
    border-radius: 100%;
    background-color: #2b2b3d;
    animation: flicker 1.5s infinite alternate;
  }

  & .row {
    width: 100%;
    align-items: center;
  }

  & .bg {
    position: relative;
    padding: 0 30px;
    z-index: 1;
    & svg {
      max-width: 85%;
      & path:global(.st0),
      & polygon:global(.st0),
      & rect {
        fill: var(--color-logo-main);
      }
      & path:global(.st1) {
        fill: var(--color-logo-second);
      }
    }
  }

  & .wrapper {
    width: 450px;
    display: flex;
    flex-direction: column;
    margin-left: 50px;

    & .form {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      gap: 20px;

      & input {
        background: var(--color-mainBg);
      }
    }

    & h3 {
      color: var(--color-font);
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  & .hidden {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 150px;
    height: 150px;
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    background-color: var(--login-flecker-bg);
  }

  20%,
  24%,
  55% {
    background-color: var(--color-main);
  }
}

@media screen and (max-width: 1000px) {
  .container {
    & .wrapper {
      width: 350px;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    &::after,
    &::before {
      display: none;
    }

    & .wrapper {
      width: auto;
      margin-left: 0;
      align-items: center;

      & .form {
        align-items: center;
      }
    }

    & .row {
      display: block;

      & .col {
        margin: auto;
      }
    }

    & .bg {
      text-align: center;
      margin-bottom: 40px;
    }

    & .hidden {
      z-index: -2;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    & .wrapper {
      width: auto;
      margin-left: 0;
    }

    & .row {
      width: auto;
      align-items: unset;
    }

    & .col {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 420px) {
}
